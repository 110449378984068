<template>
  <div class="column-center">
    <div style="position: relative">
      <img :src="icons.group_banner12" class="full-width" />
      <video
        :src="icons.group_video_artist12"
        class="group-content-width group-video"
        style="width: 70vw"
        controls
      ></video>
    </div>
    <!-- 
    <span class="group-title-white group-content-width">艺术家详情</span> -->

    <div class="column group-content-width" style="margin: 55px 0">
      <span class="group-title">苏菲·圣拉 作品</span>
      <img :src="icons.group_arrow" width="66" style="margin-top: 60px" />

      <div class="row" style="margin-top: 50px; justify-content: center">
        <img
          :src="icons.group_artist12p1"
          width="245"
          style="margin-right: 51px"
        />
        <img :src="icons.group_artist12p2" width="244" />
      </div>
    </div>

    <!-- <span class="group-page-english" style="margin-top: 80px">10-12ans</span>
    <span class="group-page-title"> 10-12岁组联创艺术家 </span>
    <img :src="icons.intro_triangle2" height="15" style="margin-top: 10px; margin-bottom: 55px" /> -->

    <div class="full-width column-center group-art-container">
      <div class="row" style="margin-bottom: 35px">
        <img :src="icons.group_triangle_left" width="15" />
        <span class="group-art-title"> 苏菲·圣拉 </span>
        <img :src="icons.group_triangle_right" width="15" />
      </div>

      <img
        :src="icons.group_artist12"
        width="450"
        style="margin-bottom: 68px"
      />

      <span class="group-art-desc group-content-width"
        >画家和雕刻家，其作品深受文学启发，曾在法国，中国，比利时，意大利，韩国，日本，美国等国家举办过近150次展览。她同时在法国参议院工作，直到
        2016 年，她负责在橘园和卢森堡花园举办的当代艺术活动 ArtSénat
        长达十年之久。</span
      >
    </div>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
};
</script>

<style scoped>
.group-content-width {
  width: 800px;
}

.group-title-white {
  font-family: PingFangSC-Medium;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: -1.23px;
  margin-top: -85px;
}

.group-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
}

.group-page-english {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.group-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}

.group-art-container {
  padding-top: 80px;
  padding-bottom: 150px;
  background-color: #ff3000;
}
.group-art-title {
  font-family: PingFangSC-Semibold;
  font-size: 48px;
  color: #ffffff;
  letter-spacing: -1.65px;
  margin: 0 35px;
}

.group-art-desc {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: -0.82px;
  text-align: center;
}
</style>